import React from 'react';

function Welcome(props) {
    return (
        <div>
            Welcome to Resume Backend
        </div>
    );
}

export default Welcome;