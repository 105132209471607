import React,{useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainApp from './MainApp';
import * as serviceWorker from './serviceWorker';
import SignInSide from './SignIn/SignInSide';
import {AppProvider} from './appContext';
import * as firebase from 'firebase/app';
import 'firebase/auth';
const AppStart =()=>{

  const [auth,setAuth] = useState(false);

  // Auth = false = (not authencated - show sign in page)
  // Auth = true = (authencated - show admin panel)


  

  // DEV
  // console.log('DEV ........');
  // let firebaseConfig = {
  //   apiKey: "AIzaSyAKV5e-6509IPVFHkRdfH4xgrfScjs6VV8",
  //   authDomain: "crud-sample-166d4.firebaseapp.com",
  //   databaseURL: "https://crud-sample-166d4.firebaseio.com",
  //   projectId: "crud-sample-166d4",
  //   storageBucket: "crud-sample-166d4.appspot.com",
  //   messagingSenderId: "1057648255535",
  //   appId: "1:1057648255535:web:29be1adc640e43260a1cda"
  // };
  // let e = "me@amitk.co.in";
  // let p= "amitk12##";

  // Prod
  let firebaseConfig = {
    apiKey: "AIzaSyBVD32t27x4mpsgqGCe4amNE5tuhiZLrKI",
    authDomain: "resume-akreact.firebaseapp.com",
    databaseURL: "https://resume-akreact.firebaseio.com",
    projectId: "resume-akreact",
    storageBucket: "resume-akreact.appspot.com",
    messagingSenderId: "779242400012",
    appId: "1:779242400012:web:7569339d47d0b7b3124129"
  };

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
 
  
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      // User is signed in.
      // console.log('User is signed in ', user);
      setAuth(true)
    } 
    else {
      // No user is signed in.
      // console.log('No user is signed in');
      setAuth(false)
    }
  });

  if(!auth){
    return (
    <SignInSide setAuth={setAuth} />
    )
  }
  else {
    return (
      <AppProvider>
        <MainApp />
      </AppProvider>
    )
  }

}

ReactDOM.render(
  <React.StrictMode>
   <AppStart />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
