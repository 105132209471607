import React, {useContext} from "react";
import {
  makeStyles,
  // createMuiTheme,
  // ThemeProvider,
} from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonIcon from '@material-ui/icons/Person';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import TranslateIcon from '@material-ui/icons/Translate';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import WorkIcon from '@material-ui/icons/Work';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import SaveIcon from '@material-ui/icons/Save';
import * as firebase from 'firebase/app';
import 'firebase/database';
import Snackbar from '@material-ui/core/Snackbar';
import Alert  from '@material-ui/lab/Alert';


import {AppContext} from './appContext';

import PersonalDetails from './Forms/personalDetails';
import Education from "./Forms/education";
import Language from "./Forms/language";
import Interests from './Forms/interests';
import Experiences from './Forms/experiences';
import Achievements from './Forms/achievements';
import Certification from './Forms/certification';
import Projects from './Forms/projects';
import Skills from './Forms/skills';
import Welcome from './pages/welcome';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor:'#161b23',
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  accountInfo: {
    float: "right",
  },
}));

// const darkTheme = createMuiTheme({
//     palette: {
//       type: 'dark',
//     },
//   });

export default function MainApp() {
  const classes = useStyles();

  const [auth, setAuth] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  let [resume, setResume] = useContext(AppContext);
  const open = Boolean(anchorEl);

  const [openSucessBar, setOpenSucessBar] = React.useState(false);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    firebase.auth().signOut().then((e)=>{
      console.log('account SignOut sucess');

    }).catch(e=>{
      console.log('error while signout', e);
    });  
  }

  //Selected ListItem
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  //End

  const [componentShow, setComponentShow] = React.useState("");

  const loadComponent = () => {
    // componentShow = name;
    // console.log('new component set');
    switch (componentShow) {
      case "PersonalDetails":
        return (<PersonalDetails ></PersonalDetails>);
      case "Education":
        return (<Education></Education>);
      case "Language":
        return (<Language></Language>);
      case "Interests":
        return (<Interests></Interests>);
      case "Experiences":
        return (<Experiences></Experiences>);
      case "Achievements":
        return (<Achievements></Achievements>);
      case "Certification":
        return (<Certification></Certification>);
      case "Projects":
        return (<Projects></Projects>);
      case "Skills":
        return (<Skills></Skills>);
      default:
        return ( <Welcome></Welcome>);
    }
    
  }

  // console.log('data reset', Resume);

  const handleSaveChanges = (event) =>{
    event.preventDefault();
    event.stopPropagation();

    console.log('save', resume)

    // Save to Firebase

    firebase.database().ref('/').update(resume).then(response=>{
      console.log('updated ', response);
      setOpenSucessBar(true);
    }).catch(error=>{
      console.log('error', error);
    });


  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSucessBar(false);
  };
  
  // const saveResume = (resume) => {
  //   // Resume = resume;
  //   // console.log('Updated',Resume);
  // }
      // <ThemeProvider theme={darkTheme}> // </ThemeProvider>

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            AmitK Resume Admin Panel
          </Typography>
          {auth && (
            <div className={classes.accountInfo}>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>View Resume</MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          {/* <Typography align="center">Left Panel</Typography> */}
          <List>
            <ListItem
              button
              selected={selectedIndex === 0}
              key="PersonalDetails"
              onClick={(e)=>{setComponentShow("PersonalDetails");handleListItemClick(e, 0)}}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Personal Details"  />
            </ListItem>
            <ListItem 
              button 
              selected={selectedIndex === 1}
              key="Education" onClick={(e)=>{setComponentShow("Education");handleListItemClick(e, 1);}}>
              <ListItemIcon>
                <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText primary="Education"  />
            </ListItem>
            <ListItem 
              button
              key="Lang"
              selected={selectedIndex === 2}
              onClick={(e)=>{setComponentShow("Language");handleListItemClick(e, 2);}}>
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>
              <ListItemText primary="Languages" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 3}
              key="Interests"
              onClick={(e)=>{setComponentShow("Interests");handleListItemClick(e, 3);}}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Interests" />
            </ListItem>
          </List>
          {/* <List>
            {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List> */}
          <Divider />
          {/* <Typography align="center">Left Panel</Typography> */}

          <List>
            <ListItem
              button
              selected={selectedIndex === 4}
              key="Experiences"
              onClick={(e)=>{setComponentShow("Experiences");handleListItemClick(e, 4);}}>
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Experiences" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 5}
              key="Achievements"
              onClick={(e)=>{setComponentShow("Achievements");handleListItemClick(e, 5);}}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Achievements" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 6}
              key="Certification"
              onClick={(e)=>{setComponentShow("Certification");handleListItemClick(e, 6);}}>
              <ListItemIcon>
                <CardMembershipIcon />
              </ListItemIcon>
              <ListItemText primary="Certification" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              selected={selectedIndex === 7}
              key="Projects"
              onClick={(e)=>{setComponentShow("Projects");handleListItemClick(e, 7);}}>
              <ListItemIcon>
                <StoreMallDirectoryIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
            <ListItem
              button
              selected={selectedIndex === 8}
              key="Skills"
              onClick={(e)=>{setComponentShow("Skills");handleListItemClick(e, 8);}}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary="Skills" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key="Save" onClick={handleSaveChanges}>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <ListItemText primary="Save Changes" />
            </ListItem>
          </List>
          <Divider />
          <Typography align="center">V1.1.200504436</Typography>
          <Typography align="center">Classic Desktop Edition</Typography>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {/* <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Rhoncus
          dolor purus non enim praesent elementum facilisis leo vel. Risus at
          ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum
          quisque non tellus. Convallis convallis tellus id interdum velit
          laoreet id donec ultrices. Odio morbi quis commodo odio aenean sed
          adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
          integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate
          eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo
          quis imperdiet massa tincidunt. Cras tincidunt lobortis feugiat
          vivamus at augue. At augue eget arcu dictum varius duis at consectetur
          lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa sapien
          faucibus et molestie ac.
        </Typography>
         */}

        {/* <AppProvider> */}

        {loadComponent()}

        {/* </AppProvider> */}


        <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} 
          open={openSucessBar} 
          autoHideDuration={2000} 
          onClose={handleSnackBarClose}>
          <Alert elevation={6} variant="filled"  onClose={handleSnackBarClose} severity="success">
            Changes has been updated sucessfully
          </Alert>
        </Snackbar>
         
      </main>
    </div>
  );
}
