import React,{ useState, createContext, useEffect } from "react";
import * as firebase from 'firebase/app';
import 'firebase/database';

export const AppContext = createContext();

export const AppProvider = props => {

  
  const [resume, setResume]= useState( {
        "achievements" : [],
        "certification" : [],
        "education" : [],
        "email" : "",
        "experiences" : [],
        "github" : "",
        "image" : "",
        "interests" : [],
        "languages" : [],
        "linkedin" : "",
        "name" : "",
        "pdf" : "",
        "phone" : "",
        "projects" : [],
        "skills" : [],
        "stackoverflow" : "",
        "twitter" : "",
        "twitterURL" : "",
        "website" : "",
        "website2" : ""
      })
      
      useEffect(() => {
        // code to run on component mount
        var database = firebase.database();
        database.ref('/').once('value').then(dbValue=>{
          console.log(dbValue.val());
          setResume(dbValue.val())
        }).catch(error=>{
          console.log('error while reading', error);
        })
      }, [])

     

    return (
        <AppContext.Provider value={[resume, setResume]}>
            {props.children}
        </AppContext.Provider>
    );
}