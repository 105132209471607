import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppContext } from "./../appContext";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  field: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "55ch",
    },
  },
  addUpdateBtn: {
    marginTop:10
  },
  cancelbtn:{
    marginTop:10,
    marginLeft:10
  }
}));

const useCardStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop:12,
    marginBottom: 3,
  },
});



export default function Skills() {
  const classes = useStyles();

  let [resume, setResume] = useContext(AppContext);

  let [addForm, setAddForm] = useState({
    name: "",
    percent: ""
  });

  let [isNew, setIsNew] = useState(true);
  let [updateIndexNo, setUpdateIndexNo] = useState(0);

  // console.log("resume on skills page", resume);

  const handleChange = (event) =>{
    event.preventDefault();
    event.stopPropagation();
    // this.setState({defaultValue: event.target.defaultValue});
    // console.log(event.target.value)
    // console.log(event.target.id);
    let formDetail = {...addForm, [event.target.id]:event.target.value}
    setAddForm(formDetail);
    // setResume(resume);
  };


  const handleAdd = (event) =>{
    event.preventDefault();
    event.stopPropagation();
    // console.log(addForm);
    var existingSkills = resume.skills;

    // Check if form is empty | return

    if(addForm.name == ''){
      alert('Validation Failed');
      return;
    }

    // Check if New Form or Update
    if(isNew){ //New
      existingSkills.unshift(addForm);
    }
    else{ //Existing
      existingSkills[updateIndexNo] = addForm;
    }

    // console.log(updateIndexNo, existingSkills);
    
    
    setResume({...resume, ["skills"]:existingSkills})

    // console.log(resume);
    resetForm();
  }

  const handeleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();

    resetForm();

  }

  const onEdit =(index) => {
    // console.log(index);
    setUpdateIndexNo(index);
    setIsNew(false);
    let existingSkills = resume.skills[index];
    setAddForm(existingSkills);
  }

  const onDelete = (index) => {
    resetForm();
    let existingSkills = resume.skills;
    existingSkills.splice(index,1);
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // console.log(result);

    const items = reorder(
      resume.skills,
      result.source.index,
      result.destination.index
    );

    // console.log('final',items);
    setResume({...resume, ["skills"]:items})

    //reset form as well
    resetForm();
  };

  const resetForm = () =>{
    setIsNew(true);
    setAddForm({
        name: "",
        percent: ""
    });
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="draggable-1">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {resume.skills.map((e,index)=>
                  <Draggable draggableId={`d-${index}`} key={index} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <SkillsDisplayCard data={e} key={index} edit={onEdit} cardIndex={index} delete={onDelete}></SkillsDisplayCard>
                      </div>
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          </DragDropContext>
        
         
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" >{isNew ? 'Add New Skills': 'Update Skills'}</Typography>
          <form className={classes.field} onChange={handleChange} noValidate autoComplete="off" onSubmit={handleAdd}>
            <TextField required id="name" onChange={handleChange} label=" Skill Name" value={addForm.name} />
            <TextField required id="percent" onChange={handleChange} label=" percent" value={addForm.percent} />
            <br />
            <Button type="submit" onClick={handleAdd} className={classes.addUpdateBtn} variant="contained">{isNew ? 'Add' : 'Update'}</Button>
            {!isNew && 
             <Button onClick={handeleCancel} className={classes.cancelbtn} variant="contained">Cancel</Button>
            }
          </form>
        </Grid>
       
      </Grid>
    </div>
  );
}



function SkillsDisplayCard(props){
  let data = props.data;
  // console.log(props)
  const cardClasses = useCardStyles();
  return(
    <React.Fragment>
      <Card className={cardClasses.root}>
        <CardHeader
          action={
            <React.Fragment>
              <IconButton aria-label="edit" onClick={(e)=>{props.edit(props.cardIndex)}}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="edit" onClick={(e)=>{props.delete(props.cardIndex)}}>
                <DeleteOutlineIcon />
              </IconButton>
            </React.Fragment>
          }
          title={data.name}
          subheader={data.percent}
        />
    
      </Card>
      <br></br>
    </React.Fragment>
  )
}