import React,{useContext} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { AppContext } from './../appContext';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  field: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "55ch",
    },
  },
}));



export default function PersonalDetails(props) {
  const classes = useStyles();

  let [resume, setResume] = useContext(AppContext);
  // console.log('hello Personal Details page', resume);

  // React.useEffect(() => {
  //   setState(props.resume);
  // }, [props.resume]);
  // let resume = state;

  const handleChange = (event) =>{
      event.preventDefault();
      event.stopPropagation();
    // this.setState({defaultValue: event.target.defaultValue});
    // console.log(event.target.value)
    // console.log(event.target.id);
    resume = {...resume, [event.target.id]:event.target.value}
    // props.onSave(resume);
    // console.log(resume);
    // console.log('page', props);
    setResume(resume);
  };



  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" >General Details</Typography>
          <form className={classes.field} onChange={handleChange} noValidate autoComplete="off">
            <TextField id="name" name="name" defaultValue={resume.name} onChange={handleChange} label="Name" />
            <TextField id="email" defaultValue={resume.email} onChange={handleChange} label="Email" />
            <TextField id="pdf"  defaultValue={resume.pdf} onChange={handleChange} label="Resume pdf Location" />
            <TextField id="image"  defaultValue={resume.image} onChange={handleChange} label="Profile Image" />
            <TextField id="twitter"  defaultValue={resume.twitter} onChange={handleChange} label="@Twitter Handle" />
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" >URLs Section</Typography>
          <form className={classes.field} noValidate autoComplete="off">
            <TextField id="github" defaultValue={resume.github} onChange={handleChange} label="Github" />
            <TextField id="stackoverflow" defaultValue={resume.stackoverflow} onChange={handleChange} label="Stack Overflow" />
            <TextField id="twitterURL" defaultValue={resume.twitterURL} onChange={handleChange} label="Twitter URL**" />
            <TextField id="website" defaultValue={resume.website} onChange={handleChange} label="Website" />
            <TextField id="website2" defaultValue={resume.website2} onChange={handleChange} label="Website 2" />
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
